<div class="content">
    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="pagina">

            <app-portada-oferta class="portada" [nOferta]="nOferta" [idIdioma]="idiomaOferta" [idCliente]="idCliente"></app-portada-oferta>

        </div>

        <div class="pagina" *ngFor="let unaPagina of paginas; let i = index">

            <div class="header">
                {{idiomaOferta}}
                <app-cabecera-mecanoviga [direccionDelegacion]="direccionDelegacion" [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion" [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga" [idIdioma]="idiomaOferta"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [idCliente]="idCliente" [letra]="letra" class="header-cliente">
                </app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>

            <div class="body">

                <div class="linea" *ngFor="let linea of unaPagina">

                    <div class="imp-total-cabecera"
                        *ngIf="linea.impCabecera === 'SI'  && linea.impSituacion === 'NO' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final' || linea.impSituacion === 'SI'">
                        <div class="linea-total-cabecera"></div>
                        <strong class="importe-cabecera">{{linea.importeCabeceraAnterior | number: '1.2-2'}} €</strong>
                    </div>

                    <div class="imp-total-situacion"
                        *ngIf="linea.impSituacion === 'SI' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final'">
                        <strong class="importe-situacion"><i style="font-size: x-large;">{{linea.situacionAnt |
                                uppercase}}</i>
                            <p style="margin: -3px 100px 0 30px;">Total:</p>{{linea.importeSituacionAnterior | number:
                            '1.2-2'}} €
                        </strong>
                    </div>

                    <div *ngIf="linea.impSituacion === 'inicial' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final'  || linea.impSituacion === 'SI'"
                        class="imp-situacion">
                        <div class="mb-10 mt-10">
                            <strong><i>{{linea.situacion | uppercase}}</i></strong>
                        </div>
                        <div class="linea-delgada"></div>
                    </div>
                    <div *ngIf="linea.impSituacion === 'SI-cont' || linea.impSituacion === 'final-cont'"
                        class="imp-situacion">
                        <strong class="mb-10 mt-10"><i>{{linea.situacion | uppercase}} cont.</i></strong>
                        <div class="linea-delgada"></div>
                    </div>

                    <div *ngIf="linea.impCabecera === 'inicial' || linea.impCabecera === 'SI' || linea.impCabecera === 'SI-cont' || linea.impSituacion === 'SI-cont' || linea.impSituacion === 'SI'"
                        class="imp-cabecera">
                        <p class="m-0 text-right flex-10">CANTIDAD</p>
                        <p class="m-0 text-left ml-30 flex-50">DESCRIPCIÓN</p>
                        <p class="m-0 flex-12">PRECIO</p>
                        <p class="m-0 flex-12" *ngIf="tieneDto"> DTO.</p>
                        <p class="m-0 flex-12" *ngIf="!tieneDto"></p>
                        <p class="m-0 flex-16 text-right mr-10">TOTAL LINEA</p>
                    </div>

                    <table class="table">
                        <tbody>
                            <tr>
                                <th scope="row" class="text-right flex-10">{{linea.cantidad}} {{linea.unidad}}</th>
                                <th scope="row" class="text-left ml-30 flex-50">{{linea.descripcion}}</th>
                                <th scope="row" class="text-right mr-20 flex-12">{{linea.precio |
                                    number: '1.2-2'}} €</th>
                                    <th *ngIf="tieneDto && linea.esKit == 1 && linea.dto !== 0" scope="row" class="text-right flex-10">
                                        {{linea.dto |
                                        number:
                                        '1.2-2'}} €</th>
                                    <th *ngIf="tieneDto && linea.esKit == 0 && linea.dto !== 0" scope="row" class="text-right flex-10">
                                        {{linea.dto |
                                        number:
                                        '1.2-2'}}</th>
                                    <th *ngIf="!tieneDto || linea.dto === 0" scope="row" class="text-right flex-10"></th>
                                    <th class="text-right flex-18" scope="row">{{linea.cantidad * linea.precio -
                                    linea.dto |
                                    number: '1.2-2'}} €</th>
                            </tr>
                        </tbody>
                    </table>

                    <div class="imp-total-cabecera"
                        *ngIf="linea.impSituacion === 'final' || linea.impSituacion === 'final-cont' || linea.impCabecera === 'final'">
                        <div class="linea-total-cabecera"></div>
                        <strong class="importe-cabecera">{{linea.importeCabecera | number: '1.2-2'}} €</strong>
                    </div>

                    <div class="imp-total-situacion"
                        *ngIf="linea.impSituacion === 'final' || linea.impSituacion === 'final-cont'">
                        <strong class="importe-situacion"><i style="font-size: x-large;">{{linea.situacionAnt |
                                uppercase}}</i>
                            <p style="margin: -3px 100px 0 30px;">Total:</p>{{linea.importeSituacion | number: '1.2-2'}}
                            €
                        </strong>
                    </div>


                </div>

            </div>

            <div class="nPagina">
                <div>{{fechaOferta | date:'dd/MM/yyyy':'es'}}</div>
                <div>Página {{i+1}} de {{nPaginas}}</div>
            </div>

        </div>

        <div class="pagina">

            <div class="header">
                <app-cabecera-mecanoviga [direccionDelegacion]="direccionDelegacion" [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion" [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga" [idIdioma]="idiomaOferta"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [idCliente]="idCliente" [letra]="letra" class="header-cliente">
                </app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>

            <div class="body">

                <div class="tituloTotalPresupuesto">
                    <strong>TOTAL PRESSUPOST:</strong>
                </div>

                <div class="totalPresupuesto">

                    <div class="linea-total-presupesto" *ngFor="let totalSituacion of totales">

                        <div style="width: 30%; text-align: end;"><strong><i>{{totalSituacion.nombre |
                                    uppercase}}</i></strong></div>
                        <div style="width: 50%;">
                            <strong>......................................................................</strong>
                        </div>
                        <div style="width: 20%; text-align: end;"><strong>{{totalSituacion.importe | number: '1.2-2'}}
                                €</strong></div>

                    </div>

                    <div class="linea-total-presupesto">
                        <div style="width: 80%; text-align: end;"></div>
                        <div style="width: 20%; text-align: end; margin-top: -15px;"><strong>_________________</strong>
                        </div>
                    </div>

                    <div class="linea-total-presupesto">
                        <div style="width: 80%; text-align: end;"></div>
                        <div style="width: 20%; text-align: end;"><strong>{{importeTotal | number: '1.2-2'}} €</strong>
                        </div>
                    </div>

                    <div class="textoPresupuesto">
                        <strong>TOTAL PRESSUPOST (IVA vigent no inclòs).</strong>
                    </div>

                </div>

            </div>

            <div class="nPagina">
                <div>{{fechaOferta | date:'dd/MM/yyyy':'es'}}</div>
                <div>Página {{paginas.length+1}} de {{nPaginas}}</div>
            </div>

        </div>

        <div class="pagina">

            <div class="header">
                <app-cabecera-mecanoviga [direccionDelegacion]="direccionDelegacion" [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion" [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [idCliente]="idCliente" [letra]="letra" class="header-cliente">
                </app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>

            <app-condiciones-oferta *ngIf="tieneTransporte" class="condiciones" [condicionesPago]="condicionesPago">
            </app-condiciones-oferta>

            <app-condiciones-oferta-sin *ngIf="!tieneTransporte" class="condiciones" [condicionesPago]="condicionesPago"
                [validezOferta]="validezOferta" [plazoEntrega]="plazoEntrega"></app-condiciones-oferta-sin>

            <div class="nPagina">
                <div>{{fechaOferta | date:'dd/MM/yyyy':'es'}}</div>
                <div>Página {{paginas.length+2}} de {{nPaginas}}</div>
            </div>

        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>
