import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TituloService } from 'src/app/services/titulo.service';
import { FiltroOfertas } from 'src/app/components/filtros/filtro-ofertas/FiltroOfertas';

@Component({
  selector: 'app-report-factura',
  templateUrl: './report-factura.component.html',
  styleUrls: ['./report-factura.component.css']
})
export class ReportFacturaComponent implements OnInit {

  public nFactura: any = "";
  public idFactura: any = 0;
  public nOferta: any = "";
  public tieneDto: boolean = true;
  public adelanto = 0;
  public contadorLineas = 0;
  public paginas: any[] = [];
  private comentarioNAlbaran = "";
  public nPagina = 0;
  public subtotal = 0;
  public totalDto = 0;
  public tipoIva = 21;


  public listaAlbaranes: any[] = [
       
        {
        nAlbaran: 100001,
        datosAlbaran: {
          lineasAlbaran: [
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
  
            
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
            {
              descripcion: "Colocación de Kits MVH",
              cantidad: 10,
              precioUni: 50,
              dto: 10,
            },
            {
              descripcion: "Entrada de material obra",
              cantidad: 1,
              precioUni: 60,
              dto: 0,
            },
            {
              descripcion: "Mortero MVH 491-680",
              cantidad: 150,
              precioUni: 57,
              dto: 20,
            },
  
          ],
          transporte: 100
        }
      },
         {
           nAlbaran: 100002,
           datosAlbaran: {
             lineasAlbaran: [
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 50,
                 precioUni: 19,
                 dto: 1000,
               },
               {
                 descripcion: "MVH12 2750",
                 cantidad: 2,
                 precioUni: 274.265,
                 dto: 0,
               },
               {
                 descripcion: "MVH12 3000",
                 cantidad: 1,
                 precioUni: 148.14,
                 dto: 0,
               },
               {
                 descripcion: "MVH12 3000 ESP 3",
                 cantidad: 1,
                 precioUni: 176.74,
                 dto: 0,
               },
             ],
             transporte: 200
           }
         },
      /*   {
           nAlbaran: 1000003,
           datosAlbaran: {
             lineasAlbaran: [
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 50,
                 precioUni: 19,
                 dto: 10,
               },
               {
                 descripcion: "MVH12 2750",
                 cantidad: 2,
                 precioUni: 274.265,
                 dto: 0,
               },
               {
                 descripcion: "MVH12 3000",
                 cantidad: 1,
                 precioUni: 148.14,
                 dto: 0,
               },
               {
                 descripcion: "MVH12 3000 ESP 3",
                 cantidad: 1,
                 precioUni: 176.74,
                 dto: 0,
               }, 
               
             ],
             transporte: 0
           }
         },
         {
           nAlbaran: 1000004,
           datosAlbaran: {
             lineasAlbaran: [
               {
                 descripcion: "Colocación de Kits MVH",
                 cantidad: 10,
                 precioUni: 50,
                 dto: 10,
               },
               {
                 descripcion: "Entrada de material obra",
                 cantidad: 1,
                 precioUni: 60,
                 dto: 0,
               },
               {
                 descripcion: "Mortero MVH 491-680",
                 cantidad: 150,
                 precioUni: 57,
                 dto: 20,
               }
             ],
             transporte: 100
           }
         },
         {
           nAlbaran: 100005,
           datosAlbaran: {
             lineasAlbaran: [
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 50,
                 precioUni: 19,
                 dto: 10,
               },
               {
                 descripcion: "MVH12 2750",
                 cantidad: 2,
                 precioUni: 274.265,
                 dto: 0,
               },
               {
                 descripcion: "MVH12 3000",
                 cantidad: 1,
                 precioUni: 148.14,
                 dto: 0,
               },
               {
                 descripcion: "MVH12 3000 ESP 3",
                 cantidad: 1,
                 precioUni: 176.74,
                 dto: 0,
               },
             ],
             transporte: 200
           }
         },
         {
           nAlbaran: 1000006,
           datosAlbaran: {
             lineasAlbaran: [
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 50,
                 precioUni: 19,
                 dto: 10,
               },
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 50,
                 precioUni: 19,
                 dto: 10,
               },
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 50,
                 precioUni: 19,
                 dto: 10,
               },
               {
                 descripcion: "MVH12 2750",
                 cantidad: 2,
                 precioUni: 274.265,
                 dto: 0,
               }
             ],
             transporte: 200
           }
         },
         {
           nAlbaran: 1000007,
           datosAlbaran: {
             lineasAlbaran: [
               {
                 descripcion: "Mortero MVV 490",
                 cantidad: 500,
                 precioUni: 19,
                 dto: 10,
               },
               {
                 descripcion: "MVH12 2750",
                 cantidad: 20,
                 precioUni: 274.265,
                 dto: 0,
               },
               {
                descripcion: "Entrada de material obra",
                cantidad: 1,
                precioUni: 60,
                dto: 0,
              },
              {
                descripcion: "Mortero MVH 491-680",
                cantidad: 150,
                precioUni: 57,
                dto: 20,
              },
              {
                descripcion: "Colocación de Kits MVH",
                cantidad: 10,
                precioUni: 50,
                dto: 10,
              },
              {
                descripcion: "Entrada de material obra",
                cantidad: 1,
                precioUni: 60,
                dto: 0,
              },
              {
                descripcion: "Mortero MVH 491-680",
                cantidad: 150,
                precioUni: 57,
                dto: 20,
              },
              {
                descripcion: "Colocación de Kits MVH",
                cantidad: 10,
                precioUni: 50,
                dto: 10,
              },
              {
                descripcion: "Entrada de material obra",
                cantidad: 1,
                precioUni: 60,
                dto: 0,
              },
              {
                descripcion: "Entrada de material obra",
                cantidad: 1,
                precioUni: 60,
                dto: 0,
              },
              {
                descripcion: "Mortero MVH 491-680",
                cantidad: 150,
                precioUni: 57,
                dto: 20,
              },
              {
                descripcion: "Colocación de Kits MVH",
                cantidad: 10,
                precioUni: 50,
                dto: 10,
              },
              {
                descripcion: "Entrada de material obra",
                cantidad: 1,
                precioUni: 60,
                dto: 0,
              },
              {
                descripcion: "Mortero MVH 491-680",
                cantidad: 150,
                precioUni: 57,
                dto: 20,
              },
              {
                descripcion: "Colocación de Kits MVH",
                cantidad: 10,
                precioUni: 50,
                dto: 10,
              },
              {
                descripcion: "Entrada de material obra",
                cantidad: 1,
                precioUni: 60,
                dto: 0,
              },
      
             ],
             transporte: 200
           },
           
         }, 
    {
      nAlbaran: 1000008,
      datosAlbaran: {
        lineasAlbaran: [
          {
            descripcion: "Colocación de Kits MVH",
            cantidad: 10,
            precioUni: 50,
            dto: 10,
          },
          {
            descripcion: "Entrada de material obra",
            cantidad: 1,
            precioUni: 60,
            dto: 0,
          },
          {
            descripcion: "Mortero MVH 491-680",
            cantidad: 150,
            precioUni: 57,
            dto: 20,
          }
        ],
        transporte: 100
      }
    },
    {
      nAlbaran: 100009,
      datosAlbaran: {
        lineasAlbaran: [
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
        ],
        transporte: 200
      }
    },
    {
      nAlbaran: 100010,
      datosAlbaran: {
        lineasAlbaran: [
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
        ],
        transporte: 200
      }
    },
    {
      nAlbaran: 100011,
      datosAlbaran: {
        lineasAlbaran: [
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
        ],
        transporte: 0
      }
    },
    {
      nAlbaran: 100012,
      datosAlbaran: {
        lineasAlbaran: [
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
          {
            descripcion: "Mortero MVV 490",
            cantidad: 50,
            precioUni: 19,
            dto: 10,
          },
          {
            descripcion: "MVH12 2750",
            cantidad: 2,
            precioUni: 274.265,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000",
            cantidad: 1,
            precioUni: 148.14,
            dto: 0,
          },
          {
            descripcion: "MVH12 3000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          },
          {
            descripcion: "MVH12 4000 ESP 3",
            cantidad: 1,
            precioUni: 176.74,
            dto: 0,
          }
        ],
        transporte: 200
      }
    }*/
  ]

  public datosFactura: any = "";
  public fFactura: any = "";
  public cPago: any = "";
  public nCuenta: any = "";
  public descripcionParaImprimir: boolean = false;
  public notas: any = "";
  public dtoPP: any = 0;
  public esAbono: boolean = false;
  public tieneLineas: number = 1;
  public importeIva: number = 0;
  public idIdioma = 2;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private tituloService: TituloService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(data => {
      this.nFactura = data.nFactura;
      this.nOferta = data.nOferta;
      let FiltroOfertas: FiltroOfertas = {
        selectedFechaDesde: undefined,
        selectedFechaHasta: undefined,
        selectedComercial: undefined,
        selectedTecnico: undefined,
        selectedCliente: undefined,
        selectedProvincia: undefined,
        selectedDireccionObra: undefined,
        selectednOferta: this.nOferta,
        selectedChecked: undefined,
        selectedCerrado: undefined,
        selectedColaboracion: undefined,
        pdf: true,
      };
      this.apiService.obtenerOfertas(FiltroOfertas).subscribe(datosOferta => {
        let datosOfe = datosOferta.response[0];
        this.idFactura = datosOfe.idIdioma;
  //      this.idIdioma = 1;
        console.log("Idioma: ", this.idIdioma);
      })
      let filtroUnaOferta = {
        selectedFechaInicio: '',
        selectedFechaFin: '',
        selectednFactura: this.nFactura,
        selectednOferta: '',
        selectedidCliente: '',
        selectedidComercial: '',
        selectedPagado: '',
        selectedAdelanto: '',
        selectedAnulada: ''
      }
      this.apiService.obtenerFactura(filtroUnaOferta).subscribe(dato => {
        this.datosFactura = dato.response[0];
        this.datosFactura.descripcion = this.datosFactura.descripcion;
        this.esAbono = this.datosFactura.esAbono;
        console.log("abono", this.esAbono);
        if (this.datosFactura.descripcion) {
          this.datosFactura.descripcion = this.datosFactura.descripcion.substr(5, this.datosFactura.descripcion.length-11);
          this.importeIva = parseFloat(this.datosFactura.importeIva);
    }
  console.log("!!: ", this.datosFactura);
        this.fFactura = new Date(this.datosFactura.fechaFactura);
        this.cPago = this.datosFactura.formaPago;
        this.nCuenta = this.datosFactura.cuenta;
        this.idFactura = this.datosFactura.idFactura;
        this.notas = this.datosFactura.notas;
        this.dtoPP = this.datosFactura.descuentoPP;
        this.tieneLineas = this.datosFactura.tieneLineas;
        this.apiService.obtenerFacturaNoVacia(this.idFactura).subscribe(data2 => {
          console.log(data2.response);
          this.tieneDto = data2.response.tieneDto;
      //    this.adelanto = data2.response.adelanto;
          this.tipoIva = data2.response.tipoIva;
          this.listaAlbaranes = data2.response.listaAlbaranes;
          this.cuentaLineas();
        });
      });


    });
  }

   private stringToHTML(str: any) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  public convertirAPdf() {
    this.apiService.modificarDescripcionPdf(this.nFactura, this.datosFactura.descripcion).subscribe();
    this.descripcionParaImprimir = true;
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`factura${this.nFactura}.pdf`);
    //  this.descripcionParaImprimir = false;
    //  this.router.navigate(['facturas'], { queryParams: { facturaResaltada: this.nFactura } });
    })
  }

  private cuentaLineas() {
    let lineasPagina = 0;
    let pagina: any[] = [];
    let cantidadAlbaranes = this.listaAlbaranes.length;
    let contadorAlbaranes = 0
    this.listaAlbaranes.forEach(albaran => {
      this.comentarioNAlbaran = "";
      contadorAlbaranes = contadorAlbaranes + 1;
      let lineasAlbaran = 2 + (albaran.datosAlbaran.lineasAlbaran.length);
      if (albaran.datosAlbaran.transporte) {
        lineasAlbaran = lineasAlbaran + 2;
      }
      lineasPagina = lineasPagina + lineasAlbaran;
      if (lineasPagina < 41) {
        pagina.push(albaran)
        lineasAlbaran = 0;
        if (contadorAlbaranes == cantidadAlbaranes) {
          this.nPagina = this.nPagina + 1;
          let element = {
            npagina: this.nPagina,
            ultimaPagina: false,
            subtotal: 0,
            dto: 0,
            albaranes: pagina
          }
          this.paginas.push(element)
        }
      } else {
        const idInicio = (albaran.datosAlbaran.lineasAlbaran.length) - (lineasPagina - 41);
        const idFin = (albaran.datosAlbaran.lineasAlbaran.length);
        const albaranInicio = albaran.datosAlbaran.lineasAlbaran.slice(0, idInicio);
        const albaranFinal = albaran.datosAlbaran.lineasAlbaran.slice(idInicio, idFin);
        const albaranInicioAGuardar = {
          nAlbaran: albaran.nAlbaran,
          datosAlbaran: {
            lineasAlbaran: albaranInicio,
            transporte: 0
          }
        }
        if (albaranInicio.length > 0) {
          pagina.push(albaranInicioAGuardar);
          this.nPagina = this.nPagina + 1;
          let element = {
            npagina: this.nPagina,
            ultimaPagina: false,
            subtotal: 0,
            dto: 0,
            albaranes: pagina
          }
          this.paginas.push(element);
          this.comentarioNAlbaran = '  CONT';
        } else {
          this.nPagina = this.nPagina + 1;
          let element = {
            npagina: this.nPagina,
            ultimaPagina: false,
            subtotal: 0,
            dto: 0,
            albaranes: pagina
          }
          this.paginas.push(element);
        }
        lineasPagina = albaranFinal.length + 4;
        pagina = [];
        let albaranFinalAGuardar = {
          nAlbaran: albaran.nAlbaran + this.comentarioNAlbaran,
          datosAlbaran: {
            lineasAlbaran: albaranFinal,
            transporte: albaran.datosAlbaran.transporte
          }
        }
        pagina.push(albaranFinalAGuardar);
        if (contadorAlbaranes == cantidadAlbaranes) {
          this.nPagina = this.nPagina + 1;
          let element = {
            npagina: this.nPagina,
            ultimaPagina: false,
            subtotal: 0,
            dto: 0,
            albaranes: pagina
          }
          this.paginas.push(element)
        }
      }
    });
    this.buscaUltimaPagina()
    console.log("PAGINAS: ", this.paginas);
  }

  private buscaUltimaPagina() {
    this.paginas.forEach(pagina => {
      if (pagina.npagina == this.paginas.length) {
        pagina.ultimaPagina = true;
      }
      pagina.albaranes.forEach((albaran: any) => {
        albaran.datosAlbaran.lineasAlbaran.forEach((linea: any) => {
          this.subtotal = this.subtotal + ((linea.cantidad * linea.precioUni))
          this.totalDto = this.totalDto + linea.dto
        });
        if (albaran.datosAlbaran.transporte) { this.subtotal = this.subtotal + albaran.datosAlbaran.transporte };
      });
      console.log("Subtotal: ", this.subtotal, "dtoPP", this.dtoPP);
      pagina.subtotal = this.subtotal;
      if(this.subtotal<0) this.esAbono = true;
      pagina.dto = this.totalDto;
    });
  }
}
