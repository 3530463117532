<div class="content">
    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="pagina">

            <app-portada-oferta [direccionDelegacion]="direccionDelegacion" [cpDelegacion]="cpDelegacion"
                [poblacionDelegacion]="poblacionDelegacion" [provinciaDelegacion]="provinciaDelegacion" class="portada"
                [nOferta]="nOferta" [idCliente]="idCliente"></app-portada-oferta>

        </div>

        <div class="pagina" *ngFor="let unaPagina of paginas; let i = index">

            <div class="header">
                <app-cabecera-mecanoviga [idIdioma]="idiomaOferta" [direccionDelegacion]="direccionDelegacion"
                    [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion"
                    [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [idCliente]="idCliente" [letra]="letra" class="header-cliente">
                </app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>

            <div class="body">

                <div class="linea" *ngFor="let linea of unaPagina">

                    <div *ngIf="linea.situacion !== linea.situacionAnt || linea.impSituacion == 'inicial'"
                        class="imp-situacion">
                        <div class="mb-10 mt-10">
                            <p><i>{{linea.situacion | uppercase}}</i></p>
                        </div>
                        <div class="linea-delgada"></div>
                    </div>
                    <div *ngIf="linea.impSituacion === 'SI-cont' || linea.impSituacion === 'final-cont'"
                        class="imp-situacion">
                        <p class="mb-10 mt-10"><i>{{linea.situacion | uppercase}} cont.</i></p>
                        <div class="linea-delgada"></div>
                    </div>

                    <div *ngIf="linea.impCabecera === 'inicial' || linea.impCabecera === 'SI' || linea.impCabecera === 'SI-cont' || linea.impSituacion === 'SI-cont' || linea.impSituacion === 'SI'"
                        class="imp-cabecera">
                        <p *ngIf="idiomaOferta != 1" class="m-0 text-right flex-10">CANTIDAD</p>
                        <p *ngIf="idiomaOferta == 1" class="m-0 text-right flex-10">QUANTITAT</p>
                        <p *ngIf="idiomaOferta != 1" class="m-0 text-left ml-30 flex-50">DESCRIPCIÓN</p>
                        <p *ngIf="idiomaOferta == 1" class="m-0 text-left ml-30 flex-50">DESCRIPCIÓ</p>
                        <p *ngIf="idiomaOferta != 1" class="m-0 flex-13">PRECIO</p>
                        <p *ngIf="idiomaOferta == 1" class="m-0 flex-13">PREU</p>
                        <p class="m-0 flex-13"
                            *ngIf="tieneDto && linea.esKit > 0 && linea.porcentaje && idiomaOferta == 1">
                            DTE.{{porcentajeDto}}%</p>
                        <p class="m-0 flex-13"
                            *ngIf="tieneDto && linea.esKit > 0 && linea.porcentaje && idiomaOferta != 1">
                            DTO.{{porcentajeDto}}%</p>

                        <p class="m-0 flex-13"
                            *ngIf="tieneDto && linea.esKit  && linea.porcentaje == 0 && idiomaOferta != 1"> DTO.</p>
                        <p class="m-0 flex-13"
                            *ngIf="tieneDto && linea.esKit  && linea.porcentaje == 0 && idiomaOferta == 1"> DTE.</p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && !linea.esKit && idiomaOferta != 1"></p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && !linea.esKit && idiomaOferta == 1"></p>
                        <p class="m-0 flex-14" *ngIf="!tieneDto"></p>
                        <p class="m-0 flex-14 text-right mr-10">TOTAL LÍNEA</p>
                    </div>

                    <table class="table">
                        <tbody>
                            <tr class="flex">
                                <th scope="row" class="text-right flex-10">{{linea.cantidad}} {{linea.unidad}}</th>
                                <th scope="row" class="text-left ml-30 flex-50">{{linea.descripcion}}</th>
                                <th scope="row" class="text-right mr-20 flex-12">{{linea.precio | number: '1.2-2'}} €
                                </th>
                                <th *ngIf="tieneDto && linea.esKit == 1 && linea.dto !== 0" scope="row"
                                    class="text-right flex-12">
                                    {{linea.dto | number: '1.2-2'}} €</th>
                                <th *ngIf="tieneDto && linea.esKit == 0 && linea.dto !== 0" scope="row"
                                    class="text-right flex-12">
                                    {{linea.dto | number: '1.2-2'}} €</th>
                                <th *ngIf="!tieneDto || linea.dto === 0" scope="row" class="text-right flex-12">
                                </th>
                                <th class="text-right flex-16" scope="row">{{linea.totalPrice | number: '1.2-2'}} €</th>
                            </tr>
                        </tbody>
                    </table>





                    <div class="imp-total-cabecera"
                        *ngIf="linea.cambiarCabecera == 'SI' || linea.cambiarSituacion == 'SI' || linea.impSituacion === 'final' || linea.impSituacion === 'final-cont' || linea.impCabecera === 'final' && linea.impSituacion === 'SI' || linea.impCabecera === 'final' && linea.impSituacion === 'NO'">
                        <div class="linea-total-cabecera"></div>
                        <p class="importe-cabecera">{{linea.importeCabecera | number: '1.2-2'}} €</p>
                    </div>
                    <!--                   <div class="imp-total-cabecera"
                        *ngIf="linea.cambiarCabecera == 'SI' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final'">
                        <div class="linea-total-cabecera"></div>
                        <p class="importe-cabecera">{{linea.importeCabecer | number: '1.2-2'}} €</p>
                    </div>

                    <div class="linea-total-cabecera" *ngIf="linea.cambiarSituacion == 'SI' || linea.impSituacion === 'final' || linea.impSituacion === 'final-cont'"></div>

                    <div class="imp-total-situacion"
                        *ngIf="linea.cambiarSituacion == 'SI'">
                        <div class="importe-situacion"><i style="font-size: x-large;">{{linea.situacion |
                                uppercase}}</i>
                            <p style="margin: -3px 100px 0px 30px;">Total:</p>{{linea.importeSituacion | number:
                            '1.2-2'}} €
                        </div>
                    </div>
-->
                    <div class="imp-total-situacion"
                        *ngIf="linea.cambiarSituacion == 'SI' || linea.impSituacion === 'final' || linea.impSituacion === 'final-cont'">
                        <div class="importe-situacion"><i style="font-size: x-large;">{{linea.situacion |
                                uppercase}}</i>
                            <p style="margin: -3px 100px 0 30px;">Total:</p>{{linea.importeSituacion | number: '1.2-2'}}
                            €
                        </div>
                    </div>




                    <!--HOLA
                    <div class="imp-total-cabecera"
                        *ngIf="linea.impCabecera === 'SI'  && linea.impSituacion === 'SI' || linea.impCabecera === 'SI'  && linea.impSituacion === 'NO' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final'">
                        <div class="linea-total-cabecera"></div>
                        <p class="importe-cabecera">{{linea.importeCabeceraAnterior | number: '1.2-2'}} €</p>
                    </div>

                    <div class="imp-total-situacion"
                        *ngIf="linea.impSituacion === 'SI' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final' && linea.impSituacionEspecial === 'SI'">
                        <div class="importe-situacion"><i style="font-size: x-large;">{{linea.situacionAnt |
                                uppercase}}</i>
                            <p style="margin: -3px 100px 0px 30px;">Total:</p>{{linea.importeSituacionAnterior | number:
                            '1.2-2'}} €
                        </div>
                    </div>

                    <div *ngIf="linea.impSituacion === 'inicial' || linea.impCabecera === 'SI'  && linea.impSituacion === 'final' && linea.impSituacionEspecial === 'SI' || linea.impSituacion === 'SI'"
                        class="imp-situacion">
                        <div class="mb-10 mt-10">
                            <p><i>{{linea.situacion | uppercase}}</i></p>
                        </div>
                        <div class="linea-delgada"></div>
                    </div>
                    <div *ngIf="linea.impSituacion === 'SI-cont' || linea.impSituacion === 'final-cont'"
                        class="imp-situacion">
                        <p class="mb-10 mt-10"><i>{{linea.situacion | uppercase}} cont.</i></p>
                        <div class="linea-delgada"></div>
                    </div>

                    <div *ngIf="linea.impCabecera === 'inicial' || linea.impCabecera === 'SI' || linea.impCabecera === 'SI-cont' || linea.impSituacion === 'SI-cont' || linea.impSituacion === 'SI'"
                        class="imp-cabecera">
                        <p *ngIf="idiomaOferta != 1" class="m-0 flex-10 text-right">CANTIDAD</p>
                        <p *ngIf="idiomaOferta == 1" class="m-0 flex-10 text-right">QUANTITAT</p>
                        <p *ngIf="idiomaOferta != 1" class="m-0 text-left ml-30 flex-50">DESCRIPCIÓN</p>
                        <p *ngIf="idiomaOferta == 1" class="m-0 text-left ml-30 flex-50">DESCRIPCIÓ</p>
                        <p *ngIf="idiomaOferta != 1" class="m-0 flex-13">PRECIO</p>
                        <p *ngIf="idiomaOferta == 1" class="m-0 flex-13">PREU</p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && linea.esKit > 0 && linea.porcentaje && idiomaOferta == 1">
                            DTO.{{porcentajeDto}}%</p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && linea.esKit > 0 && linea.porcentaje && idiomaOferta != 1">
                            DTE.{{porcentajeDto}}%</p>

                        <p class="m-0 flex-13" *ngIf="tieneDto && linea.esKit  && linea.porcentaje == 0 && idiomaOferta != 1"> DTO.</p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && linea.esKit  && linea.porcentaje == 0 && idiomaOferta == 1"> DTE.</p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && !linea.esKit && idiomaOferta != 1"></p>
                        <p class="m-0 flex-13" *ngIf="tieneDto && !linea.esKit && idiomaOferta == 1"></p>
                        <p class="m-0 flex-14" *ngIf="!tieneDto"></p>
                        <p class="m-0 flex-14 text-right mr-10">TOTAL LÍNEA</p>
                    </div>

                    <table class="table">
                        <tbody>
                            <tr>
                                <th scope="row" class="text-right flex-10">{{linea.cantidad}} {{linea.unidad}}</th>
                                <th class="text-left ml-30 flex-50" scope="row">{{linea.descripcion}}</th>
                                <th scope="row" class="text-right mr-20 flex-12">{{linea.precio |
                                    number: '1.2-2'}} €</th>
                                <th *ngIf="tieneDto && linea.esKit == 1 && linea.dto !== 0" scope="row"
                                    class="text-right flex-12">
                                    {{linea.dto |
                                    number:
                                    '1.2-2'}} €</th>
                                <th *ngIf="tieneDto && linea.esKit == 0 && linea.dto !== 0" scope="row"
                                    class="text-right flex-12">
                                    {{linea.dto |
                                    number:
                                    '1.2-2'}} €</th>
                                <th *ngIf="!tieneDto || linea.dto === 0" scope="row" class="text-right flex-12">
                                </th>
                                <th class="text-right flex-16" scope="row">{{linea.totalPrice | number: '1.2-2'}} €</th>
                            </tr>
                        </tbody>
                    </table>

                    <div class="imp-total-cabecera"
                        *ngIf="linea.impSituacion === 'final' || linea.impSituacion === 'final-cont' || linea.impCabecera === 'final' && linea.impSituacion === 'SI' || linea.impCabecera === 'final' && linea.impSituacion === 'NO'">
                        <div class="linea-total-cabecera"></div>
                        <p class="importe-cabecera">{{linea.importeCabecera | number: '1.2-2'}} €</p>
                    </div>

                    <div class="imp-total-situacion"
                        *ngIf="linea.impSituacion === 'final' || linea.impSituacion === 'final-cont'">
                        <div class="importe-situacion"><i style="font-size: x-large;">{{linea.situacion |
                                uppercase}}</i>
                            <p style="margin: -3px 100px 0 30px;">Total:</p>{{linea.importeSituacion | number: '1.2-2'}}
                            €
                        </div>
                    </div>
                -->
                </div>

                @if(i == this.paginas.length-1 && !this.totalesAppearBottom()) {
                <ng-container *ngTemplateOutlet="totalesTemplate"></ng-container>
                }
            </div>


            <div class="nPagina">
                <div>{{fechaOferta | date:'dd/MM/yyyy':'es'}}</div>
                <div *ngIf="idiomaOferta != 1">Página {{i+1}} de {{nPaginas}}</div>
                <div *ngIf="idiomaOferta == 1">Pàgina {{i+1}} de {{nPaginas}}</div>
            </div>


        </div>

        @if(this.totalesAppearBottom()) {
        <div class="pagina">

            <div class="header">
                <app-cabecera-mecanoviga [idIdioma]="idiomaOferta" [direccionDelegacion]="direccionDelegacion"
                    [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion"
                    [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [idCliente]="idCliente" [letra]="letra" class="header-cliente">
                </app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>


            <div class="body">
                <ng-container *ngTemplateOutlet="totalesTemplate"></ng-container>
            </div>

            <div class="nPagina">
                <div>{{fechaOferta | date:'dd/MM/yyyy':'es'}}</div>
                <div *ngIf="idiomaOferta != 1">Página {{paginas.length+1}} de {{nPaginas}}</div>
                <div *ngIf="idiomaOferta == 1">Pàgina {{paginas.length+1}} de {{nPaginas}}</div>
            </div>

        </div>
        }

        <div class="pagina">

            <div class="header">
                <app-cabecera-mecanoviga [idIdioma]="idiomaOferta" [direccionDelegacion]="direccionDelegacion"
                    [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion"
                    [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [idCliente]="idCliente" [letra]="letra" class="header-cliente">
                </app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>

            <app-condiciones-oferta *ngIf="tieneTransporte" class="condiciones" [idiomaOferta]="idiomaOferta"
                [validezOferta]="validezOferta" [condicionesPago]="condicionesPago">
            </app-condiciones-oferta>

            <app-condiciones-oferta-sin *ngIf="!tieneTransporte" class="condiciones" [idiomaOferta]="idiomaOferta"
                [condicionesPago]="condicionesPago" [validezOferta]="validezOferta" [plazoEntrega]="plazoEntrega">
            </app-condiciones-oferta-sin>

            <div class="nPagina">
                <div>{{fechaOferta | date:'dd/MM/yyyy':'es'}}</div>
                @if(this.totalesAppearBottom()) {
                <div *ngIf="idiomaOferta != 1">Página {{paginas.length+2}} de {{nPaginas}}</div>
                <div *ngIf="idiomaOferta == 1">Pàgina {{paginas.length+2}} de {{nPaginas}}</div>
                } @else {
                <div *ngIf="idiomaOferta != 1">Página {{paginas.length+1}} de {{nPaginas}}</div>
                <div *ngIf="idiomaOferta == 1">Pàgina {{paginas.length+1}} de {{nPaginas}}</div>
                }
            </div>

        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>

<ng-template #totalesTemplate>
    <!-- <app-totales></app-totales> -->
    <div id="totalesTemplate">

        <div class="tituloTotalPresupuesto">
            <p *ngIf="idiomaOferta != 1">TOTAL PRESUPUESTO:</p>
            <p *ngIf="idiomaOferta == 1">TOTAL PRESSUPOST:</p>

        </div>

        <div class="totalPresupuesto">

            <div class="linea-total-presupesto" *ngFor="let entry of totales | keyvalue">
                <div *ngIf="entry.value.importe !== 0" style="width: 30%; text-align: end; margin-top: 20px">
                    <p><i>{{ entry.key | uppercase }}</i></p>
                </div>
                <div *ngIf="entry.value.importe !== 0" style="width: 50%;">
                    <p>......................................................................</p>
                </div>
                <div *ngIf="entry.value.importe !== 0" style="width: 20%; text-align: end;">
                    <p>{{ entry.value.importe | number: '1.2-2' }} €</p>
                </div>
            </div>

            <div class="linea-total-presupesto">
                <div style="width: 80%; text-align: end;"></div>
                <div style="width: 20%; text-align: end; margin-top: -15px;">
                    <p>_________________</p>
                </div>
            </div>

            <div class="linea-total-presupesto" style="margin-top: 20px">
                <div style="width: 80%; text-align: end;"></div>
                <div style="width: 20%; text-align: end;">
                    <p>{{importeTotal | number: '1.2-2'}} €</p>
                </div>
            </div>

            <div *ngIf="dtoOferta != 0" class="linea-total-presupesto" style="margin-top: 20px">

                <div style="width: 30%; text-align: end;">
                    <p><i>DESCUENTO</i></p>
                </div>
                <div style="width: 50%;">
                    <p>......................................................................</p>
                </div>
                <div style="width: 20%; text-align: end;">
                    <p>{{dtoOferta | number: '1.2-2'}}
                        €</p>
                </div>

            </div>

            <div *ngIf="dtoOferta != 0" class="linea-total-presupesto">
                <div style="width: 80%; text-align: end;"></div>
                <div style="width: 20%; text-align: end; margin-top: -15px;">
                    <p>_________________</p>
                </div>
            </div>

            <div *ngIf="dtoOferta != 0" class="linea-total-presupesto" style="margin-top: 20px">
                <div style="width: 80%; text-align: end;"></div>
                <div style="width: 20%; text-align: end;">
                    <p>{{importeTotal - dtoOferta | number: '1.2-2'}} €</p>
                </div>
            </div>



            <div class="textoPresupuesto">
                <p *ngIf="idiomaOferta != 1">TOTAL PRESUPUESTO (IVA vigente no incluido).</p>
                <p *ngIf="idiomaOferta == 1">TOTAL PRESSUPOST (IVA vigent no inclòs).</p>
            </div>

        </div>
        <div *ngIf="notas" class="notasText">
            <!-- <textarea class="notas" name="" id="" cols="" rows="8">{{notas}}</textarea> -->
            <br>
            <div align="left" [innerHtml]="notas"></div>

        </div>

    </div>
    <span *ngIf="getHeightTemplate()"></span>

</ng-template>