import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { FiltroOfertas } from 'src/app/components/filtros/filtro-ofertas/FiltroOfertas';
import { TituloService } from 'src/app/services/titulo.service';


@Component({
  selector: 'app-report-oferta',
  templateUrl: './report-oferta.component.html',
  styleUrls: ['./report-oferta.component.css']
})
export class ReportOfertaComponent implements OnInit {

  private paramsUrl: any = "";
  public letra = "P";
  public paginas: any[] = [];
  public nPaginas: number = 0;
  public idiomaOferta = 2;

  public nOferta: any = "";
  public idCliente: any = "";

  public tieneDto: boolean = false;
  public porcentajeDto: number = 0;
  public tieneTransporte: boolean = false;
  public condicionesPago = "";
  public dtoOferta = 0;
  public validezOferta = "";
  public plazoEntrega = "";
  public fechaOferta = "";
  public notas = "";
  public poblacionDelegacion = "Vallirana";
  public direccionDelegacion = "Polígono Industrial Can Prunera, C/. del Garraf, nº 16";
  public cpDelegacion = "08759";
  public provinciaDelegacion = "Barcelona";
  public idDelegacion = "";
  public importeTotal = 0;

  totales: { [key: string]: { importe: number } } = {};

  datosOferta = [
    {
      situacion: "Planta Sótano",
      situacionAnt: "",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      descripcion: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje: 0,
      totalPrice: 0
    },
  ]

  public freeSpaceCuentaLienas: number = 0;
  public templateHeight: number = 0;
  public lineHeight: number = 31.83;
  public linesPerPage: number = 39;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private tituloService: TituloService,
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
      this.nOferta = params.nOferta;
      this.idCliente = params.nCliente;
      let FiltroOfertas: FiltroOfertas = {
        selectedFechaDesde: undefined,
        selectedFechaHasta: undefined,
        selectedComercial: undefined,
        selectedTecnico: undefined,
        selectedCliente: this.idCliente,
        selectedProvincia: undefined,
        selectedDireccionObra: undefined,
        selectednOferta: this.nOferta,
        selectedChecked: undefined,
        selectedCerrado: undefined,
        selectedColaboracion: undefined,
        pdf: true,
      };
      this.apiService.obtenerOfertas(FiltroOfertas).subscribe(datosOferta => {
        let datosOfe = datosOferta.response[0];
        console.log("datosOfe: ", datosOfe);
        this.condicionesPago = datosOfe.nombreCondicionesPago;
        this.dtoOferta = datosOfe.dtoPP;
        console.log("DTO: ", this.dtoOferta);
        this.validezOferta = datosOfe.nombreValidezOferta;
        this.plazoEntrega = datosOfe.plazoEntrega;
        this.fechaOferta = datosOfe.fechaOferta;
        this.idDelegacion = datosOfe.idDelegacion;
        this.idiomaOferta = datosOfe.idIdioma;
        this.notas = datosOfe.notas;
        this.apiService.obtenerDelegacionParams(this.idDelegacion).subscribe((datos: any) => {
          console.log(datos.response);
          this.direccionDelegacion = datos.response[0].direccionDelegacion;
          this.cpDelegacion = datos.response[0].cpDelegacion;
          this.poblacionDelegacion = datos.response[0].poblacionDelegacion;
          this.provinciaDelegacion = datos.response[0].provinciaDelegacion;
        })
      })

      this.apiService.detalleOfertaPDF(this.nOferta, this.idCliente).subscribe(data => {
        this.datosOferta = data.response;
        this.tieneTransporte = data.response[0]['transporte'];
        this.datosOferta.forEach(item => {
          if (!this.tieneDto && item['dto'] != 0) {
            this.tieneDto = true;
            if (item.esKit) {
              this.porcentajeDto = item.porcentaje
            }
          }
        });
        /*this.apiService.obtenerPrecioFinal(this.nOferta, this.idCliente).subscribe(data => {
          this.totales = data.response;
          console.log("TOTALES: ", this.totales);
          this.totales.forEach(item => {
            this.importeTotal += item['importe'];
          })
        });*/
        this.cuentaLineas();
      });
    });
  }

  public getHeightTemplate() {
    if (this.templateHeight == 0) {
      setTimeout(() => {
        let template = document.getElementById('totalesTemplate');
        if (template) {
          this.templateHeight = template.getBoundingClientRect().height;
        }
      }, 1000);
    }
    return false;
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      //  console.log(devicePixelRatio);
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/svg');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`oferta${this.nOferta}.pdf`);
      this.router.navigate([this.paramsUrl.redirecTo], { queryParams: this.paramsUrl });

    })
  }

  private cuentaLineas() {
    let nombreSituacion = "inicial";
    let imprimirSituacion: any = "";
    let impSituacionEsp: any = "SI";
    let imprimirCabecera: any = "";
    let lineasPagina: number = 0;
    let pagina: any[] = [];
    let contadorProductos = 0;
    let esUnKit: any = "inicial";
    let situacionAnterior = this.datosOferta[0].situacion;
    let totalCabecera = 0;
    let totalSituacion = 0;
    let totalCabeceraAnterior = 0;
    let totalSituacionAnterior = 0;
    let cambioSituacion = "NO";
    let cambioCabecera = "NO";

    this.datosOferta.forEach(item => {
      contadorProductos = contadorProductos + 1;
      //    console.log(item, nombreSituacion);
      if (nombreSituacion === 'inicial') {
        //       console.log("1");
        lineasPagina = lineasPagina + 2;
        imprimirSituacion = 'inicial';
        nombreSituacion = item.situacion;
      } else {
        //       console.log("2");
        if (nombreSituacion !== item.situacion) {
          let i = pagina.length - 1;
          console.log("3", pagina[i]);
          let paginaCambio = pagina[i];
          paginaCambio.cambiarSituacion = "SI";
          lineasPagina = lineasPagina + 6.5
          imprimirSituacion = "SI";
          nombreSituacion = item.situacion;
        } else {
          //         console.log("4");
          imprimirSituacion = "NO";
        }
      }
      if (esUnKit === 'inicial') {
        //       console.log("5");
        imprimirCabecera = 'inicial';
        esUnKit = item.esKit;
        lineasPagina = lineasPagina + 2
      } else {
        //       console.log("6");
        if (esUnKit !== item.esKit) {
          console.log("7");
          let i = pagina.length - 1;
          let paginaCambio = pagina[i];
          paginaCambio.cambiarCabecera = "SI";
          imprimirCabecera = "SI";
          esUnKit = item.esKit;
          lineasPagina = lineasPagina + 5;
        } else {
          //         console.log("8");
          imprimirCabecera = "NO"
          if (imprimirSituacion === "SI") {
            //           console.log("9");
            imprimirCabecera = "SI-cont"
            lineasPagina = lineasPagina + 2
            if (item.situacion !== item.situacionAnt) {
              //             console.log("10");
              imprimirCabecera = "SI";
            } else {
              //             console.log("11");
              imprimirCabecera = "NO";
            }
          }
        }
      }

      lineasPagina = lineasPagina + 1
      this.freeSpaceCuentaLienas = (this.linesPerPage - lineasPagina) * this.lineHeight;
      console.log(contadorProductos, lineasPagina);
      if (lineasPagina > this.linesPerPage) {
        this.paginas.push(pagina);
        let a = this.paginas[this.paginas.length - 1];
        let b = a[a.length - 1];
        if (imprimirCabecera === "SI") { b.impCabecera = "final" };
        //      if (imprimirSituacion === "SI") {this.paginas[this.paginas.length-1].impSituacion = "final"};
        lineasPagina = 0;
        if (imprimirSituacion === "NO") {
          imprimirSituacion = "SI-cont";
        }
        else { imprimirSituacion = "SI"; }
        //    imprimirCabecera = "SI-cont";
        esUnKit = item.esKit;
        lineasPagina = lineasPagina + 5
        lineasPagina = lineasPagina + 1
        pagina = [];
        totalCabeceraAnterior = totalCabecera;
        totalSituacionAnterior = totalSituacion;
        
        ({ totalCabecera, totalSituacion } = this.calculateTotals(imprimirCabecera, totalCabecera, imprimirSituacion, totalSituacion, item));

        situacionAnterior = this.datosOferta[contadorProductos - 2].situacion;
        //      console.log("SA: ", situacionAnterior);
        let element = {
          ...item,
          impSituacionEspecial: impSituacionEsp,
          impSituacion: imprimirSituacion,
          impCabecera: imprimirCabecera,
          situacionAnt: situacionAnterior,
          importeCabecera: totalCabecera,
          importeSituacion: totalSituacion,
          importeCabeceraAnterior: totalCabeceraAnterior,
          importeSituacionAnterior: totalSituacionAnterior,
          cambiarSituacion: cambioSituacion,
          cambiarCabecera: cambioCabecera

        }
        console.log("ELEMENT: ", element);;
        pagina.push(element);
        cambioSituacion = "NO";
        cambioCabecera = "NO";
        if (contadorProductos === this.datosOferta.length) {
          //        console.log("PISSSSOOOOO", imprimirSituacion);
          //        console.log(pagina[pagina.length - 1].impSituacion);
          //        console.log(pagina[pagina.length - 1].situacion);
          //        console.log(pagina[pagina.length - 1].situacionAnt);
          if (imprimirSituacion === "SI-cont") {
            pagina[pagina.length - 1].impSituacion = "final-cont";
          } else {
            pagina[pagina.length - 1].impSituacion = "final";
            situacionAnterior = this.datosOferta[contadorProductos - 2].situacion;
            if (imprimirSituacion === "SI") {
              if (pagina[pagina.length - 1].situacion !== pagina[pagina.length - 1].situacionAnt) {
                pagina[pagina.length - 1].impSituacionEspecial = "SI";
                pagina[pagina.length - 1].situacionAnt = pagina[pagina.length - 1].situacionAnt;
              } else {
                pagina[pagina.length - 1].impSituacionEspecial = "NO";
              }
            }
          }
          this.paginas.push(pagina);
        }
      }
      else {
        if (contadorProductos > 1) {
          situacionAnterior = this.datosOferta[contadorProductos - 2].situacion;
        }
        totalCabeceraAnterior = totalCabecera;
        totalSituacionAnterior = totalSituacion;

        ({ totalCabecera, totalSituacion } = this.calculateTotals(imprimirCabecera, totalCabecera, imprimirSituacion, totalSituacion, item));
        let element = {
          ...item,
          impSituacionEspecial: impSituacionEsp,
          impSituacion: imprimirSituacion,
          impCabecera: imprimirCabecera,
          situacionAnt: situacionAnterior,
          importeCabecera: totalCabecera,
          importeSituacion: totalSituacion,
          importeCabeceraAnterior: totalCabeceraAnterior,
          importeSituacionAnterior: totalSituacionAnterior,
          cambiarSituacion: cambioSituacion,
          cambiarCabecera: cambioCabecera
        }
        console.log("Element: ", element);
        pagina.push(element);
        cambioSituacion = "NO";
        cambioCabecera = "NO";
        //console.log(contadorProductos, lineasPagina);
        if (contadorProductos === this.datosOferta.length) {
          //       console.log("PASOOOO", imprimirSituacion)
          //console.log("1: ", pagina);
          if (imprimirSituacion === "SI") {
            if (pagina[pagina.length - 1].situacion !== pagina[pagina.length - 1].situacionAnt) {
              pagina[pagina.length - 1].impSituacionEspecial = "SI";
              pagina[pagina.length - 1].situacionAnt = pagina[pagina.length - 1].situacionAnt;
            } else {
              pagina[pagina.length - 1].impSituacionEspecial = "NO";
            }
          }
          pagina[pagina.length - 1].impSituacion = "final";
          this.paginas.push(pagina);
        }
      }
    });
    console.log("PAGINAS: ", this.paginas);

  }

  private calculateTotals(imprimirCabecera: any, totalCabecera: number, imprimirSituacion: any, totalSituacion: number, item: { situacion: string; situacionAnt: string; esKit: boolean; cantidad: number; unidad: string; descripcion: string; precio: number; dto: number; porcentaje: number; totalPrice: number; }) {
    if (imprimirCabecera === "SI") { totalCabecera = 0; };
    if (imprimirSituacion === "SI") { totalSituacion = 0; totalCabecera = 0; };
    item.totalPrice = Math.round((item.cantidad * item.precio - item.dto) * 100) / 100;
    if (!this.totales[item.situacion]) {
      this.totales[item.situacion] = { importe: 0 };
    }
    this.totales[item.situacion].importe += item.totalPrice;
    this.importeTotal += item.totalPrice;

    totalCabecera = totalCabecera + item.totalPrice;
    totalSituacion = totalSituacion + item.totalPrice;
    return { totalCabecera, totalSituacion };
  }

  public totalesAppearBottom() {
    if (this.templateHeight == 0 || this.freeSpaceCuentaLienas == 0 || this.freeSpaceCuentaLienas < this.templateHeight) {
      this.nPaginas = this.paginas.length + 2;
      return true;
    }
    this.nPaginas = this.paginas.length + 1;
    return false;
  }
}
