import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TituloService } from 'src/app/services/titulo.service';
// Joan post-upgrade: Ho comento de moment per poder compilar després de migrar Angular de 12 a 15. De totes formes no veig que aquí s'utilitzi
//import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-informe-material-vendido',
  templateUrl: './informe-material-vendido.component.html',
  styleUrls: ['./informe-material-vendido.component.css']
})
export class InformeMaterialVendidoComponent implements OnInit {

  public fechaConsulta = new Date();
  public arrayItems: any[] = [];
  public paginasArrayItems: any[] = [];
  public anio = "";
  private tipoProducto = "";
  public nombreProducto = "";
  public totalMes1 = 0;
  public totalMes2 = 0;
  public totalMes3 = 0;
  public totalMes4 = 0;
  public totalMes5 = 0;
  public totalMes6 = 0;
  public totalMes7 = 0;
  public totalMes8 = 0;
  public totalMes9 = 0;
  public totalMes10 = 0;
  public totalMes11 = 0;
  public totalMes12 = 0;
  public totalMeses = 0;


  constructor(
    private apiService: ApiService,
    private activatedRouter: ActivatedRoute,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.arrayItems = [
      {
        descripcion: "MORTERO MVV",
        c1: 11,
        c2: 12,
        c3: 13,
        c4: 14,
        c5: 15,
        c6: 16,
        c7: 17,
        c8: 0,
        c9: 19,
        c10: 20,
        c11: 21,
        c12: 22
      },
      {
        descripcion: "MORTERO MVH",
        c1: 21,
        c2: 22,
        c3: 23,
        c4: 24,
        c5: 25,
        c6: 26,
        c7: 27,
        c8: 0,
        c9: 29,
        c10: 30,
        c11: 31,
        c12: 32
      },
      {
        descripcion: "CEMENTO PORTLAND",
        c1: 21,
        c2: 22,
        c3: 23,
        c4: 24,
        c5: 25,
        c6: 26,
        c7: 27,
        c8: 0,
        c9: 29,
        c10: 30,
        c11: 31,
        c12: 32
      },
      {
        descripcion: "MVH12",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH16",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },

      {
        descripcion: "MVH20",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH12",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH16",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },

      {
        descripcion: "MVH20",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH12",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH16",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },

      {
        descripcion: "MVH20",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH12",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
      {
        descripcion: "MVH16",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },

      {
        descripcion: "MVH20",
        c1: 41,
        c2: 42,
        c3: 43,
        c4: 44,
        c5: 45,
        c6: 46,
        c7: 47,
        c8: 0,
        c9: 59,
        c10: 50,
        c11: 51,
        c12: 52
      },
    ]

    this.activatedRouter.queryParams.subscribe(data => {
      this.anio = data.anio;
      this.tipoProducto = data.tipoProductoElegido;
      console.log("Tipo producto: ", this.tipoProducto);
      if (this.tipoProducto == "1") {
        this.nombreProducto = "Hierro"
      } else if (this.tipoProducto == "3") {
        this.nombreProducto = "Tornilleria"
      } else if (this.tipoProducto == "4") {
        this.nombreProducto = "Especial"
      } else if (this.tipoProducto == "5") {
        this.nombreProducto = "Otros"
      }
       this.apiService.obtenerMaterialVendido(this.anio, this.tipoProducto).subscribe(datos => {  
        this.arrayItems = datos.response;
        console.log("RESPUESTA: ", this.arrayItems);
        this.sumasMes();
        this.prepararPaginas();
      })
    })
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 2);
  }


  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('l', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`informe-material-vendido${this.anio}.pdf`);
    })
  }

  private convertDate(date: any) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  }

  private prepararPaginas() {
    let pagina: any[] = [];
    let nPagina = 0;
    let contadorLineas = 0;
    let contadorTotalLineas = 0
    const totalLineas = this.arrayItems.length;
    for (let i = 0; i < totalLineas; i++) {
      contadorLineas = contadorLineas + 1;
      contadorTotalLineas = contadorTotalLineas + 1;
      
      pagina.push(this.arrayItems[0]);
      this.arrayItems.splice(0, 1);
      if (contadorLineas === 28) {
        nPagina = nPagina + 1;
        let element = {
          datos: pagina,
          nPagina: nPagina
        }
        this.paginasArrayItems.push(element);
        pagina = [];
        contadorLineas = 0
      } else if (contadorTotalLineas === totalLineas) {
        nPagina = nPagina + 1;
        let element = {
          datos: pagina,
          nPagina: nPagina
        }
        this.paginasArrayItems.push(element);
        pagina = [];
      } else { }
    }
    console.log("PAGINAS: ", this.paginasArrayItems);
  }

  private sumasMes() {
    this.arrayItems.forEach(data => {
      data.c1= parseInt(data.c1)
      data.c2= parseInt(data.c2)
      data.c3= parseInt(data.c3)
      data.c4= parseInt(data.c4)
      data.c5= parseInt(data.c5)
      data.c6= parseInt(data.c6)
      data.c7= parseInt(data.c7)
      data.c8= parseInt(data.c8)
      data.c9= parseInt(data.c9)
      data.c10= parseInt(data.c10)
      data.c11= parseInt(data.c11)
      data.c12= parseInt(data.c12)
      data.peso = parseFloat(data.peso)
      this.totalMes1 = this.totalMes1 + data.c1 * data.peso;
      this.totalMes2 = this.totalMes2 + data.c2 * data.peso;
      this.totalMes3 = this.totalMes3 + data.c3 * data.peso;
      this.totalMes4 = this.totalMes4 + data.c4 * data.peso;
      this.totalMes5 = this.totalMes5 + data.c5 * data.peso;
      this.totalMes6 = this.totalMes6 + data.c6 * data.peso;
      this.totalMes7 = this.totalMes7 + data.c7 * data.peso;
      this.totalMes8 = this.totalMes8 + data.c8 * data.peso;
      this.totalMes9 = this.totalMes9 + data.c9 * data.peso;
      this.totalMes10 = this.totalMes10 + data.c10 * data.peso;
      this.totalMes11 = this.totalMes11 + data.c11 * data.peso;
      this.totalMes12 = this.totalMes12 + data.c12 * data.peso;
    });
    this.totalMeses = this.totalMes1 + this.totalMes2 + this.totalMes3 + this.totalMes4 + this.totalMes5 + this.totalMes6 +
    this.totalMes7 + this.totalMes8 + this.totalMes9 + this.totalMes10 + this.totalMes11 + this.totalMes12;
  }
}
